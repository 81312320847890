import React from 'react';
import {Link} from 'gatsby';

import styles from './Toolbar.module.css';
// import Logo from '../../Logo/Logo';
import ImageLogo from '../../../image-logo';
import NavigationItems from '../NavigationItems/NavigationItems';
import DrawerToggle from '../SideDrawer/DrawerToggle/DrawerToggle';

const toolbar = (props) => (
	<div className={styles.Toolbar}>
		<div className={styles.Logo}>
			<Link
				to="/"
				style={{
					color: `black`,
					textDecoration: `none`
				}}
			>
				<ImageLogo />
			</Link>
		</div>
		<DrawerToggle clicked={props.drawerToggleClicked} />
		<nav className={styles.DesktopOnly}>
			<NavigationItems />
		</nav>
	</div>
);

export default toolbar;
