import React from 'react';
// import {NavLink} from 'react-router-dom';
import {Link} from 'gatsby';

import styles from './NavigationItem.module.css';

const navigationItem = (props) => (
	<li className={styles.NavigationItem}>
		<Link to={props.link} activeClassName={styles.active}>
			{props.children}
		</Link>
	</li>
);

export default navigationItem;
