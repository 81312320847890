import React, {useState} from 'react';

import styles from './NavLayout.module.css';
// import Toolbar from '../../components/Navigation/Toolbar/Toolbar';
// import SideDrawer from '../../../components/Navigation/SideDrawer/SideDrawer';

import SideDrawer from '../Navigation/SideDrawer/SideDrawer';
import Toolbar from '../Navigation/Toolbar/Toolbar';
const Layout = () => {
	const [ showSideDrawer, setShowSideDrawer ] = useState(false);

	const sideDrawerClosedHandler = () => {
		setShowSideDrawer(false);
	};

	const sideDrawerToggleHandler = () => {
		setShowSideDrawer(!showSideDrawer);
	};

	return (
		<React.Fragment>
			<Toolbar drawerToggleClicked={sideDrawerToggleHandler} />
			<SideDrawer open={showSideDrawer} closed={sideDrawerClosedHandler} />
		</React.Fragment>
	);
};

export default Layout;
