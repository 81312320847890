import React from 'react';

//import Logo from '../../Logo/Logo';
import NavigationItems from '../NavigationItems/NavigationItems';
import styles from './SideDrawer.module.css';
import Backdrop from '../../../ui/backdrop/backdrop';
import ImageLogo from '../../../image-logo';

const sideDrawer = (props) => {
	let attachedClasses = [ styles.SideDrawer, styles.Close ];
	if (props.open) {
		attachedClasses = [ styles.SideDrawer, styles.Open ];
	}
	return (
		<React.Fragment>
			<Backdrop show={props.open} clicked={props.closed} />
			<div className={attachedClasses.join(' ')}>
				<div className={styles.Logo}>
					<ImageLogo />
				</div>
				<nav>
					<NavigationItems />
				</nav>
			</div>
		</React.Fragment>
	);
};

export default sideDrawer;
