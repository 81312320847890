import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import ThreeColumnGrid from "../components/threeColumnGrid/threeColumnGrid"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInstagram } from "@fortawesome/free-brands-svg-icons"
import { faFacebook } from "@fortawesome/free-brands-svg-icons"
import { faLinkedin } from "@fortawesome/free-brands-svg-icons"

const Footer = ({ siteTitle }) => (
  <footer
    style={{
      background: `#F7F7F7`,
      // marginBottom: `1.45rem`,
    }}
  >
    <ThreeColumnGrid
      first={
        <React.Fragment>
          <Link to="/privatlivs-politik/">Privatlivspolitik</Link> <br />
        </React.Fragment>
      }
      // second={<ContactForm />}
      second={
        <React.Fragment>
          <p>
            Email:{" "}
            <a href="mailto:atar.gafni@gmail.com<?subject=Henvendelse om polstring">
              Skriv her
            </a>
          </p>
          <p>
            Telefon: <a href="tel:61720245">61720245</a>
          </p>
          <p>Uplandsgade 72</p>
          <p>2300 København S</p>
          <p>CVR: 40871330</p>
          <p>© Studio Gafni {new Date().getFullYear()}</p>
        </React.Fragment>
      }
      third={
        <React.Fragment>
          <p>Følg mig her:</p>
          <a
            href="https://www.instagram.com/studio_gafni/"
            target="_blank"
            rel="noreferrer"
            style={{ marginRight: "15px" }}
          >
            <FontAwesomeIcon icon={faInstagram} size="2x" />
          </a>

          <a
            href="https://www.facebook.com/studiogafni"
            target="_blank"
            rel="noreferrer"
            style={{ marginRight: "15px" }}
          >
            <FontAwesomeIcon icon={faFacebook} size="2x" />
          </a>

          <a
            href="https://www.linkedin.com/in/atar-gafni/"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faLinkedin} size="2x" />
          </a>
        </React.Fragment>
      }
    />
    {/* ©   {new Date().getFullYear()}, Built with
    {` `}
    <a href="https://www.gatsbyjs.org">Gatsby</a> */}
  </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
