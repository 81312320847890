import React from "react"

import styles from "./NavigationItems.module.css"
import NavigationItem from "./NavigationItem/NavigationItem"

const navigationItems = () => (
  <ul className={styles.NavigationItems}>
    <NavigationItem link="/">Hjem</NavigationItem>
    <NavigationItem link="/mobelpolstring/">Møbelpolstring</NavigationItem>
    <NavigationItem link="/pyntepuder/">Pyntepuder</NavigationItem>
  </ul>
)

export default navigationItems
