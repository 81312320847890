import {Link} from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
// import BackgroundImage from '../components/backgroundImage/backgroundImage';
import NewBackgroundImage from './image-new-header';
// import ImageLogo from '../components/image-logo';
import Layout from './nav/NavLayout/NavLayout';

const Header = ({siteTitle, noHeaderImage}) => (
	<header
		style={{
			background: `white`,
			marginBottom: `1.45rem`
		}}
	>
		<div
			style={{
				margin: `0 auto`,
				maxWidth: `960px`
				// padding: `0.50rem 1.0875rem`
			}}
		>
			{/* <h1 style={{ margin: 0 }}> */}
			<Link
				to="/"
				style={{
					color: `black`,
					textDecoration: `none`
				}}
			>
				{/* {siteTitle} */}
				{/* <ImageLogo /> */}
			</Link>
			{/* </h1> */}
			<Layout />
		</div>
		{!noHeaderImage ? <NewBackgroundImage /> : null}
	</header>
);

Header.propTypes = {
	siteTitle: PropTypes.string
};

Header.defaultProps = {
	siteTitle: ``
};

export default Header;
