import React from 'react';
import styles from './backdrop.module.css';

const Backdrop = ({show, clicked}) => {
	return show ? (
		<div className={styles.Backdrop} onClick={clicked} onKeyDown={clicked} role="button" tabIndex={0} />
	) : null;
};

export default Backdrop;
