import React from 'react';
// import PropTypes from "prop-types"
import styles from './threeColumnGrid.module.css';

const ThreeColumnGrid = (props) => {
	const containerClassName = props.imagePosition === 'right' ? styles.ContainerRight : styles.ContainerLeft;

	return (
		<div className={containerClassName}>
			<div className={styles.Item} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
				<div className={styles.Content}>{props.first}</div>
			</div>
			<div className={styles.Item} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
				<div className={styles.Content}>{props.second}</div>
			</div>
			<div className={styles.Item} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
				<div className={styles.Content}>{props.third}</div>
			</div>
		</div>
	);
};

// ThreeColumnGrid.propTypes = {
//   children: PropTypes.node.isRequired,
// }

export default ThreeColumnGrid;
